body {
	background-color: #8fca5c;
}

nav {
	text-align: center;
	width: 50%;
	top: 0%;
	margin-right: auto;
	margin-left: auto;
	align-items: center;
	padding-bottom: 10px;
	background-color: #3ab3da;
	box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 600px) {
	nav {
		width: 80%;
	}
}

.logo {
	height: 140px;
	margin-bottom: -28px;
	margin-top: 10px;
	padding-bottom: 0px;
}

.do {
	display: flex;
	justify-content: center;
}

.info {
	height: 30px;
	margin-bottom: -6px;
	filter: invert(1);
	margin-right: 10px;
}

nav h1 {
	color: #477a1e;
	font-size: 30px;
}
nav hr {
	width: 40%;
	border: 1.6px solid white;
}

.gameContainer {
	margin-top: 40px;
	color: black;
	display: flex;
	justify-content: center;
}

.gameComponents {
	display: grid;
	grid-auto-flow: row;
	justify-content: center;
	justify-items: center;
}

.gameComponents hr {
	width: 40%;
	border: 1.6px solid white;
	margin-bottom: 0px;
}

.hangman {
	height: 400px;
}

.hangTest {
	height: 400px;
	margin-bottom: -30px;
	filter: invert(0);
	position: absolute;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	text-align: center;
}
.shake {
	animation: horizontal-shaking 0.3s 0.2s;
}
.answer {
	text-align: center;
	margin-top: 0px;
	color: #3c3c3c;
	margin-bottom: 0px;
	width: 70%;
}
.answer h5 {
	margin-top: 20px;
	border-radius: 5px;

	margin-bottom: 0px;
}
.answer h1 {
	border-radius: 5px;
	margin-top: 30px;

	margin-left: auto;
	font-size: 30px;
	letter-spacing: 5px;
	margin-right: auto;
}

.letters {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 40%;
	grid-gap: 10px;
	margin-bottom: 90px;
	margin-top: 15px;
	row-gap: 20px;
}

@media only screen and (max-width: 839px) {
	.letters {
		width: 43%;
	}
}

@media only screen and (max-width: 780px) {
	.letters {
		width: 47%;
	}
}
@media only screen and (max-width: 715px) {
	.letters {
		width: 50%;
	}
}

@media only screen and (max-width: 670px) {
	.letters {
		width: 54%;
	}
}

@media only screen and (max-width: 620px) {
	.letters {
		width: 57%;
	}
}

@media only screen and (max-width: 590px) {
	.letters {
		width: 60%;
	}
}

@media only screen and (max-width: 560px) {
	.letters {
		width: 64%;
	}
}
@media only screen and (max-width: 524px) {
	.letters {
		width: 68%;
	}
}

@media only screen and (max-width: 505px) {
	.letters {
		width: 70%;
	}
}

@media only screen and (max-width: 480px) {
	.letters {
		width: 76%;
	}
}

@media only screen and (max-width: 440px) {
	.letters {
		width: 80%;
	}
}
@media only screen and (max-width: 420px) {
	.letters {
		width: 85%;
	}
}
@media only screen and (max-width: 400px) {
	.letters {
		width: 90%;
	}
}
@media only screen and (max-width: 373px) {
	.letters {
		width: 91%;
	}
}
@media only screen and (max-width: 365px) {
	.letters {
		width: 95%;
	}
}
@media only screen and (max-width: 350px) {
	.letters {
		width: 100%;
	}
}

.remove {
	position: absolute;
	top: 2%;
	right: 2%;
	font-weight: 600;
	background-color: transparent;
	color: black;
	border: none;
	font-size: 30px;
	cursor: pointer;
}

.letters button {
	padding: 2px 2px;
	background-color: transparent;
	color: white;
	border: none;
	text-decoration: double;
	font-weight: 600;
	font-size: 30px;
	border-radius: 5px;
	cursor: pointer;
	transition-duration: 0.2s;
	transition-property: all;
	transition-timing-function: ease-in;
}

.letters button:hover {
	background-color: transparent;
	text-decoration: overline;
	color: white;
}

.letter.no {
	color: #a52a2a;
	text-decoration: none;
	animation: horizontal-shaking 0.3s 0.2s;
}
.letter.no:hover {
	color: #a52a2a;
	background-color: transparent;
	text-decoration: none;
}
@keyframes horizontal-shaking {
	0% {
		transform: translateX(0);
	}
	25% {
		transform: translateX(5px);
	}
	50% {
		transform: translateX(-5px);
	}
	75% {
		transform: translateX(5px);
	}
	100% {
		transform: translateX(0);
	}
}

.tempButt {
	background-color: transparent;
	color: transparent;
	font-weight: 600;
	border: none;
	cursor: pointer;
}

.tempButt:hover {
	background-color: red;
	color: white;
	font-weight: 600;
	border: none;
	cursor: pointer;
}

.help {
	position: absolute;
	transition: 0.5s;
	background-color: white;
	z-index: 999;
	width: 440px;
	height: 740px;
	top: 17%;
	border-radius: 5px;
	opacity: 1;
	text-align: center;
	box-shadow: 0 0 0 9999px #000000b0;
}
.help h2 {
	color: #3ab3da;
	text-decoration: underline;
}
.help p {
	color: black;
	margin-left: auto;
	margin-right: auto;
	text-align: justify;
	width: 90%;
}

@media only screen and (max-width: 505px) {
	.help {
		width: 380px;
		height: 800px;
	}
}
@media only screen and (max-width: 414px) {
	.help {
		width: 360px;
		height: 800px;
	}
}

.trys {
	color: red;
	font-weight: 600;
}

.mistakes {
	display: grid;
	grid-auto-flow: column;
	justify-items: center;
	grid-gap: 2px;
	justify-content: center;
	margin-top: 0px;
}

.hangle {
	font-weight: bold;
}

.mistakeExp {
	height: 100px;
}
.mistakes figcaption {
	font-size: 10px;
	font-weight: 600;
}
.mistakes h5 {
	font-weight: 600;
	text-align: center;
	margin: 0px;
	margin-bottom: 10px;
	color: #3ab3da;
}

.exp {
	margin-bottom: 0px;
}

.summary {
	position: absolute;
	top: 24%;
	transition: 0.5s;
	background-color: white;
	color: #3ab3da;
	z-index: 999;
	width: 350px;
	height: fit-content;
	border-radius: 5px;
	opacity: 1;
	text-align: center;
	box-shadow: 0 0 0 9999px #000000b0;
}

.duck {
	height: 25px;
}

.summary p {
	width: 70%;
	text-align: left;
	margin-left: auto;
	margin-right: auto;
	margin-top: 0px;
}

.summary h3 {
	margin: 0px;
	color: #212121;
	font-weight: 600;
}

.countryReveal {
	color: green;
}
.Toastify__toast-body {
	font-weight: 600;
	color: black;
}

.summary table {
	margin-left: auto;
	margin-right: auto;
	text-align: left;
	table-layout: fixed;
	color: #212121;
	margin-bottom: 30px;
}
.flag {
	height: 55px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	margin-bottom: 10px;
	border: 2px solid black;
	border-radius: 2px;
}
.summary tr {
	font-size: 22px;
}
.summary td {
	font-size: 20px;
	padding-top: 10px;
	color: #61371f;
	text-align: center;
	font-weight: 600;
	padding: 10px;
	padding-left: 5px;
	border-bottom: 2px solid #61371f;
}
.summary th {
	padding-right: 25px;
	padding-top: 10px;

	color: #61371f;
	padding: 8px;

	border-bottom: 2px solid #61371f;
}

.summary h2 {
	padding-top: 20px;
	opacity: 1;
	margin-bottom: 18px;
}

.progress {
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin: 0px;
	grid-gap: 0px;
	justify-content: center;
}

.progress progress {
	margin: 0px;
}

.hint {
	margin-bottom: -20px;
	margin-top: 5px;
	color: white;
	background-color: red;
	border-radius: 5px;
	padding: 1px 5px;
	font-weight: 600;
	bottom: 0%;
	font-size: 13px;
	transition: all;
	transition-duration: 0.3s;
}

.twitter {
	text-decoration: none;
	background-color: #212121;
	color: white;
	border-radius: 5px;
	padding: 7px 20px;

	cursor: pointer;
	bottom: 5%;
}
.backScramble {
	background-color: #013b3f;
}

.ad {
	padding-top: 10px;
	padding-bottom: 0px;
	font-weight: 600;
	margin-top: 10px;
	margin-bottom: 10px;
	color: white;
}

.scramble img {
	height: 180px;
	margin-bottom: 10px;
}

.summary h3 {
	font-size: 40;
	font-weight: 600;
}

.footer {
	text-align: center;
	position: relative;
	left: 0;
	bottom: 0%;
	width: 100%;
	background-color: #212121;
	color: white;
	height: 30px;
	width: 100%;
	font-size: 15px;
}
.footer h4 {
	padding-top: 5px;
	font-weight: 400;
}
.othman {
	background-color: #25283d;
	color: lightgreen;
	text-decoration: none;
	padding: 0px 4px;
	border-radius: 5px;
	font-weight: 600;
	transition-duration: 0.3s;
}
.othman:hover {
	background-color: lightgreen;
	color: #25283d;
}
